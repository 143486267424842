<template>
  <inline-svg :src="src" class="icons" />
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "icon",
  props: {
    name: { type: String },
  },
  components: { InlineSvg },
  computed: {
    src() {
      if (!this.name) {
        return "";
      }

      return `/images/svg/${this.name}.svg`;
    },
  },
};
</script>

<style lang="scss"></style>
